/*global define */

define('facebook',
  ['jquery', '_strings'],
  function ($) {
    "use strict";

    function textmsg(str) {
      return str.replace(/(\r\n)|(\n\r)|\r|\n/g, "<br>");
    }

    // show 3 entries from facebook
    var SOCIAL_MEDIA_LIMIT = 3;

    var SOCIAL_MEDIA_FALLBACK_IMAGE = (function(){
      if (location.host.indexOf("localhost") !== -1) {
        return "images/fb-newtalents-logo.png";
      } else {
        return "/fileadmin/base/images/fb-newtalents-logo.png";
      }
    })();

    var fbFetch = function() {
      var url = "https://graph.facebook.com/newtalentscologne/posts?limit=10&date_format=U&access_token=599026036849086|qgbPObbbgYV8gtzDcjOWrr2ReJg&callback=?";

      $.getJSON(url, function(json) {
        var e = 0;
        var html = "";
        $.each(json.data, function(i, fb) {

          if(e < SOCIAL_MEDIA_LIMIT) {
            // ===== POST =====
            if ((fb.message !== undefined) || (fb.story !== undefined)) {
            html += "<div class='post'>";

              // ===== IMG =====
              html += "<div class='img'>";
              if (fb.picture !== undefined) { html += "<img src='" + fb.picture + "' alt='' width='50px' /> "; }
              if (fb.picture === undefined) { html += '<img src="'+SOCIAL_MEDIA_FALLBACK_IMAGE+'" width="50px"  />'; }
              html += "</div>";

              // Link zum Pinnwandeintrag (Einzelansicht)
              var fbid = fb.id;
              var fblinkpost = fbid.split('_');

              // ===== TEXT =====
              html += "<div class='social-content'>";
              if (fb.created_time !== undefined) {
                var d = new Date(fb.created_time*1000);
                html += "<p class='date'>"+('0' + d.getDate()).slice(-2)+"."+('0' + (d.getMonth()+1)).slice(-2)+"."+d.getFullYear()+"</p>";
              }

              if (fb.message !== undefined) { html += textmsg(fb.message.truncateSafe(170, "... <a href='http://www.facebook.com/newtalentscologne/posts/"+fblinkpost[1]+"'>mehr</a>")); }


                // ===== NAVBAR =====
                html += "<ul class='navbar'>";

                  // ===== LINK POST =====
                  html += "<li><a class='beitrag' href='http://www.facebook.com/newtalentscologne/posts/" + fblinkpost[1] + "'><span class='icon icon-facebook'></span> Eintrag</a></li>";

                  // ===== VIDEOS =====
                  if (fb.type === 'video') {
                    var video = fb.source.replace('http://www.youtube.com/v/', 'http://www.youtube.com/embed/');
                    html += '<li><a class="showvideo" href=' + video + '><span class="icon icon-camera"></span> Video</a></li>';
                  }

                  // ===== LINK =====
                  if (fb.type === 'link') { html += '<li><a class="externallink" href="' + fb.link + '"><span class="icon icon-link"></span> Link</a></li>'; }

                html += "</ul>";

              html += "</div>";
            html += "</div>";

            e++;

            }
          }
        });

        $("#fb-timeline").removeClass("timeline-loading");
        $('#fb-timeline').html(html);
      });
    };


    fbFetch();


  }
);
