require.config({
  wrap: true,
  paths: {
    async: "lib/async",
    breakpoint: 'lib/jquery.breakpoints',
    owl: 'lib/owl.carousel.min',
    owlSlide: 'lib/owl.slide',
    infobox: 'lib/infobox'
  }
});

define('modernizr', [], window.Modernizr);

requirejs([
  'jquery',
  'modernizr',
  'breakpoint',
  'owl',
  'owlSlide'
  ],
  function($, modernizr) {
    $(function() {
 

      // ==============================================
      //  CONTENT SLIDER
      // ==============================================
      $(".content-slider").owlSlide({
        slideSpeed : 300,
        paginationSpeed : 300,
        rewindSpeed : 300,
        navigationText : false,
        navigation:true
      });


      // ==============================================
      //  LOCATIONS LISTING
      // ==============================================
      var initLocationListingToggle = function() {
        $(".location-wrap .location").each(function(index, element) {
          var locationTitle = $(element).find(".location-content .location-address-title").text();
          $("<span class='btn btn-with-icon location-listing-btn'>"+locationTitle+"<span class='icon icon-Dreieckspfeil_unten'></span></span>").insertBefore($(element));
        });
      };

      if($(".location-wrap").length) {
        initLocationListingToggle();
      }

      $(".location-listing-btn").on('click', function(ev) {
        var $this = $(this);
        var $icon = $this.find(".icon");
        ev.preventDefault();
        $icon.toggleClass("icon-Dreieckspfeil_unten");
        $icon.toggleClass("icon-Dreieckspfeil_oben");
        $this.toggleClass("active");
        $this.next(".location").stop(true, true).slideToggle(175);
      });



      // ==============================================
      //  NAV MAIN TOGGLE
      // ==============================================
      $('#menutoggle').on('click', function(ev) {
        ev.preventDefault();
        $(this).siblings(".nav-main").stop(true, true).slideToggle(150);
      });



      // ==============================================
      //  KEYVISUAL DATE
      // ==============================================
      var initCountdown = function() {
        var $countdownContainer = $("#countdown");
        var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        var currentDate = new Date();
        var secondDate = new Date(2014,04,10); // year,month-1,day (in JS January is 0, December is 11)
        var diffDays = Math.ceil((secondDate-currentDate)/oneDay);

        // ENGLISCHE ANPASSUNG !
        if (diffDays > 1) {
          $countdownContainer.append("Nur noch "+ diffDays +" Tage");
        }
        if (diffDays === 1) {
          $countdownContainer.append("Nur noch "+diffDays+" Tag");
        }
        if (diffDays === 0) {
          $(".slider-item-countdown").remove();
        }
      };

      initCountdown();



      // ==============================================
      //  KEYVISUAL SLIDER
      // ==============================================
      var initSlider = function() {
        var owl = $("#keyvisual-slider");
        owl.owlCarousel({
          navigation : true,
          slideSpeed : 400,
          paginationSpeed : 400,
          rewindSpeed : 400,
          singleItem:true,
          navigationText : false,
          lazyLoad : true,
          autoPlay : 5000,
          stopOnHover : true
        });
      };



      // ==============================================
      //  NO SVG SUPPORT
      // ==============================================
      if(!modernizr.svg) {
        $('img[src$="svg"]').attr('src', function() {
          return $(this).attr('src').replace('.svg', '.png');
        });
      }



      // ==============================================
      //  LINK WHOLE COLUMN (QUERVERWEISE)
      // ==============================================
      var $querverweise = $(".querverweise");
      var $querverweiseItem = $querverweise.find("li");
      if($querverweise.length) {
        $querverweiseItem.each(function(i, el) {
          var href = $(el).find("a:first").attr("href");
          var title = $(el).find("a:first").text();
          $(el).wrapInner("<a href='"+href+"' title='"+title+"' class='querverweise-linked'></a>");
        });
      }



      // ==============================================
      //  CONVERT MAIL ADRESS
      // ==============================================
      require(['convertMailAddress'], function (convertMailAddress) {
        convertMailAddress();
      });



      // ==============================================
      //  TIMELINES
      // ==============================================
      if($("#fb-timeline").length) {
        require(['facebook']);
      }



      // ==============================================
      //  MAPS
      // ==============================================
      require(['maps'], function () {
        });



      // ==============================================
      // BREAKPOINTS
      // ==============================================
      var onDesktopNormal = function () {
        $(".location").removeAttr("style");
        $(".nav-main").removeAttr("style");
        initSlider();
      };

      var mobileHorz = function () {

      };

      var mobileVert = function () {

      };

      $(window).breakpoints({
        callback: function(device) {
          switch(device) {
            case "desktop-normal":
              onDesktopNormal();
              break;
            case "mobile-horz":
              mobileHorz();
              break;
            case "mobile-vert":
              mobileVert();
              break;
            default:
              break;
          }
        },
        eventNamespace: 'rm',
        helperElementId: 'rm-helper'
      });


    });
  }
);
define("main", function(){});

