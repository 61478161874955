/*jshint loopfunc: true, undef: true, unused: true*/
/*global define, google, require, zoomin, zoomout */

define('maps',
  [
    'jquery',
    'async!https://maps.googleapis.com/maps/api/js?v=3.exp&sensor=false&language=de!callback'
  ],
  function ($) {
    "use strict";

    var $map = $('#map');


    // ==============================================
    //  MAP SETTINGS
    // ==============================================
    var maxZoomLevel = 18;
    var mapSettings = {
      desktop: {
        size: "220x160",
        scale: 1,
        zoom: 12,
        sensor: false,
        marker_shadow: false
      },
      phoneHorz: {
        size: "578x420",
        scale: 1,
        zoom: 18,
        sensor: false,
        marker_shadow: false
      },
      phoneVert: {
        size: "320x218",
        scale: 1,
        zoom: 17,
        sensor: false,
        marker_shadow: false
      }
    };

    // check if touch device or not: get 'true' or 'false' and add this to map-options
    var touchDevice = !('ontouchend' in document.documentElement);


    // ==============================================
    //  MAP STYLES
    // ==============================================
    var styles = [
      {
        "elementType": "geometry",
        "stylers": [
          { "saturation": -100 },
          { "lightness": 22 }
        ]
      },{
        "featureType": "water",
        "stylers": [
          { "color": "#00a0e6" },
          { "lightness": 50 },
          { "saturation": -33 }
        ]
      },{
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          { "lightness": -16 }
        ]
      },{
        "featureType": "road",
        "stylers": [
          { "visibility": "simplified" }
        ]
      },{
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
          { "visibility": "on" },
          { "saturation": -100 },
          { "lightness": 29 }
        ]
      },{
        "featureType": "poi.business",
        "stylers": [
          { "visibility": "off" }
        ]
      }
    ];


    var makeStaticStyle = function (styles) {
      var params = [];
      var i, _len = styles.length;
      var styleRule;
      for (i = 0; i < _len; i++) {
        styleRule = [];

        if (styles[i].featureType !== 'all' && styles[i].featureType) {
          styleRule.push('feature:' + styles[i].featureType);
        }

        if (styles[i].elementType !== 'all' && styles[i].elementType) {
          styleRule.push('element:' + styles[i].elementType);
        }

        if (!styles[i].stylers) { continue; }

        var j, _len2 = styles[i].stylers.length;
        for (j = 0; j < _len2; j++) {
          var p, ruleArg;
          for (p in styles[i].stylers[j]) {
            ruleArg = styles[i].stylers[j][p];
            if (p === 'hue' || p === 'color') {
              ruleArg = '0x' + ruleArg.substring(1);
            }
            styleRule.push(p + ':' + ruleArg);
          }
        }

        var rule = styleRule.join('|');
        if (rule !== '') {
          params.push('style=' + rule);
        }
      }

      return params.join('&');
    };



    // ==============================================
    //  LOCATIONS
    // ==============================================
    var locations = [];
    var coordiantes = [];

    var $locationWrap = $(".location-wrap"),
        $location = $(".location");

    if($locationWrap.length) {
      $($location).each(function (i, e) {
        var $e = $(e);

        var title = $e.find(".location-address-title").text();
        var content = $e.find(".location-content").html();
        var coordLat = $e.data("lat");
        var coordLong = $e.data("long");
        var marker = $e.find(".marker img").prop('src');

        locations.push({
            title: title,
            info: content,
            lat: coordLat,
            long: coordLong,
            marker_url: marker
        });

        // coordinates to fit all markes in view
        coordiantes.push({
          latlng: new google.maps.LatLng(coordLat, coordLong)
        });

      });
    }



    // ==============================================
    //  FUNCTIONAL MAP
    // ==============================================

    // ===== CUSTOM CONTROLS =====
    function CustomControls(controlDiv, map) {

    google.maps.event.addDomListener(zoomout, 'click', function() {
      var currentZoomLevel = map.getZoom();

      if(currentZoomLevel !== 0){
        map.setZoom(currentZoomLevel - 1);
      }

      $("#zoomin").removeClass("disabled");
      if(currentZoomLevel <= 1) {
        $("#zoomout").addClass("disabled");
      }
    });

    google.maps.event.addDomListener(zoomin, 'click', function() {
      var currentZoomLevel = map.getZoom();
      if(currentZoomLevel !== 21){
        map.setZoom(currentZoomLevel + 1);
      }

      $("#zoomout").removeClass("disabled");
      if(currentZoomLevel+1 === maxZoomLevel) {
        $("#zoomin").addClass("disabled");
      }
    });

    }

    // ===== INIT FUNCTIONAL MAP =====
    var initFunctionalMap = function () {
      if (!$map.length) {
        return;
      }

      var $mapCanvas = $('#map-canvas');
      if (!$mapCanvas.length) {
        $mapCanvas = $('<div id="map-canvas"/>').appendTo($map);
      }

      // ===== MAP OPTIONS =====
      var mapOptions = {
          center: new google.maps.LatLng(50.9360941,6.9513969),
          zoom: 15,
          maxZoom: 18,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          scrollwheel: false,
          draggable: touchDevice,
          streetViewControl: false,
          disableDefaultUI: true,
          panControl: true,
          panControlOptions: {
              position: google.maps.ControlPosition.RIGHT_TOP
          },
          styles: styles
      };

      var map = new google.maps.Map($mapCanvas[0], mapOptions);


      // ===== CUSTOMCONTROLS =====
      $(".l-header").append('<div class="map-zoom-controls"><div class="zoom-btn" id="zoomin">+</div><div class="zoom-btn" id="zoomout">-</div></div>');
      var homeControlDiv = document.createElement('div');
      new CustomControls(homeControlDiv, map);



      // ===== INFOBOXES =====
      var closeIcon = (function(){
        if (location.host.indexOf("localhost") !== -1) {
          return "images/icon-x.png";
        } else {
          return "/fileadmin/base/images/icon-x.png";
        }
      })();

      require(['infobox'], function (InfoBox) {

        var ib = new InfoBox();
        ib.isOpen = false;


        /* SET MARKERS */
        for (var i = locations.length - 1; i >= 0; i--) {
          (function(loc){
            var markerOptions = {
              position: new google.maps.LatLng(loc.lat, loc.long),
              icon: loc.marker_url,
              title: loc.title,
              animation: google.maps.Animation.DROP
            };

            var marker = new google.maps.Marker(markerOptions);
            marker.setMap(map);

            // InfoBox instead of InfoWindow
            // cf. http://google-maps-utility-library-v3.googlecode.com/svn/trunk/infobox/docs/reference.html
            var myOptions = {
              content: '<div class="location">'+loc.info+'</div>',
              alignBottom: true,
              boxClass: 'location-infowindow',
              maxWidth: 424,
              closeBoxURL: closeIcon,
              closeBoxMargin: "-13px -13px 0px 0px",
              pixelOffset: new google.maps.Size(-212, -75)
            };

            marker.ibOptions = myOptions;

            google.maps.event.addListener(marker, 'click', (function(marker) {
                return function() {
                    ib.setOptions(marker.ibOptions);
                    ib.open(map, marker);
                    ib.isOpen = true;
                    map.setCenter(marker.getPosition());
                    map.panBy(0,-180);
                };
            })(marker));

          })(locations[i]);
        }
      });
    };

    // ===== REMOVE FUNCTIONAL MAP =====
    var removeFunctionalMap = function () {
      $('#map-canvas').remove();
    };



    // ==============================================
    // STATIC MAPS
    // ==============================================
    var createStaticMap = function(element, locations, device) {

      var staticmap = [
          "http://maps.googleapis.com/maps/api/staticmap?",
          "center="+[locations.lat, locations.long].join(',')+"&",
          "zoom="+mapSettings[device].zoom,"&",
          "size="+mapSettings[device].size,"&",
          "scale="+mapSettings[device].scale,"&",
          "sensor="+mapSettings[device].sensor,"&",
          "format=png","&",
          "maptype=roadmap&visual_refresh=true","&",
          makeStaticStyle(styles),"&",
          "markers="+locations.lat+","+locations.long+""
          //"markers=icon:"+locations.marker_url+"|"+locations.lat+","+locations.long+""
        ].join('');

      $(element).find('.static-map').append("<img src='"+staticmap+"'/>");
    };

    // ===== INIT STATIC MAPS =====
    var initStaticMap = function (device) {
      if($locationWrap.length) {
        $($location).each(function (i, e) {
          var $e = $(e);
          createStaticMap($e, locations[i], device);
        });
      }
    };

    // ===== REMOVE STATIC MAPS =====
    var removeStaticMap = function () {
      $('.static-map img').remove();
    };



    // ==============================================
    // BREAKPOINTS
    // ==============================================
    var onDesktopNormal = function () {
      initFunctionalMap(location);
      removeStaticMap();
    };

    var mobileHorz = function () {
      removeFunctionalMap();
      removeStaticMap();
      initStaticMap('phoneHorz');
    };

    var mobileVert = function () {
      removeFunctionalMap();
      removeStaticMap();
      initStaticMap('phoneVert');
    };

    $(window).breakpoints({
      callback: function(device) {
        switch(device) {
          case "desktop-normal":
            onDesktopNormal();
            break;
          case "mobile-horz":
            mobileHorz();
            break;
          case "mobile-vert":
            mobileVert();
            break;
          default:
            break;
        }
      },
      eventNamespace: 'rm',
      helperElementId: 'rm-helper'
    });


  }
);

