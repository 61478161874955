/*global define */

define('convertMailAddress', [], function () {
  "use strict";
  return function () {
    var emailElements;
    if (document.getElementsByClassName) {
      emailElements = document.getElementsByClassName("m-convert");
    } else {
      emailElements = [];
      var allSpanElements = document.getElementsByTagName('span');
      for (var i = 0; i < allSpanElements.length; i++) {
        if (allSpanElements[i].className === 'm-convert') {
          emailElements.push(allSpanElements[i]);
        }
      }
    }
    var elementContent, replaceContent;
    for (var j = 0; j < emailElements.length; j++) {
      elementContent = emailElements[j].innerHTML;
      replaceContent = elementContent.replace(" [at] ", "&#64;");
      replaceContent = replaceContent.replace(" [punkt] ", ".");
      emailElements[j].innerHTML = '<a href="mailto:' + replaceContent + '" title="'+replaceContent+'">'+replaceContent+'</a>';
    }
  };
});
