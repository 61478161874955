(function($) {
  $.fn.breakpoints =
    function (params) {
      // Merge options into defaults
      var options = $.extend({}, $.fn.breakpoints.defaults, params),
        currentDevice, detectedDevice;

      // Add helper div if not existent
      if ($('#' + options.helperElementId).length < 1) {
        $('<div></div>')
          .prop('id',options.helperElementId)
          .appendTo(document.body);
      }

      // Our custom breakpointChange event
      // will call the callback option function
      $(document).on('breakpointChange.' + options.eventNamespace, function(event, device) {
        if (typeof options.callback == 'function') {
          options.callback.call(this, device);
        }
      });

      // Get the font-family of helper element
      // alter currentDevice when changed
      var detectDeviceChange = function() {
        detectedDevice = $('#' + options.helperElementId).css('font-family');
        if (detectedDevice != currentDevice) {
          currentDevice = detectedDevice;
          $(document).trigger('breakpointChange.' + options.eventNamespace, currentDevice);
        }
      };

      // resize event binding and initial triggering
      $(window).on('orientationchange resize', function(){
        detectDeviceChange();
      });
      $(window).trigger('resize');

    };

  /**
   * Plugin defaults
   */
  $.fn.breakpoints.defaults = {
    eventNamespace: 'default',
    helperElementId: 'helper',
    callback: function(device){
      if (window.console === true) {
        console.log('detected device change to '+ device);
      }
    }
  };

})(jQuery);
define("breakpoint", function(){});

