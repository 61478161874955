/*global define */

define('_strings', [], function () {
  "use strict";
  // ==============================================
  //  Strings
  // ==============================================
  String.prototype.stripTags = function() {
    return this.replace(/<\/?[^>]+>/ig, '');
  };

  if (typeof String.prototype.trim !== 'function') {
    String.prototype.trim = function() {
      return this.replace(/^\s+|\s+$/g, '');
    };
  }

  // truncate, but don't split words
  String.prototype.truncateSafe = function(length, truncateStr) {
    truncateStr = truncateStr || '...';
    return this.stripTags().length > length ?
    this.stripTags().substring(0, length).replace(/\w+$/, '').trim() + truncateStr
    : this;
  };
});
